import { Layer, Source } from 'react-map-gl';
import React from 'react';
// import { mapVariables } from '../config'

function PolygonLayer({
  item,
  beforeId,
  id,
  lineColor = 'white',
  type = 'geojson',
  layerType = 'line',
  fillOpacity = 1,
  fillColor = 'white',
  fillOutLineColor = 'white',
}) {
  // TODO: This should be done with a match query - requries mapbox v2.3, which requires react-map-gl v7 upgrade:
  // (['match',
  //   ['get', 'provisional'],
  //   true, [3,5], [5,5]
  //   ])

  const lineDashArray = item?.properties?.provisional ? [3, 5] : [1000, 1];

  const paint = {
    fill: {
      'fill-color': fillColor,
      'fill-opacity': fillOpacity,
      'fill-outline-color': fillOutLineColor,
    },
    line: {
      'line-color': lineColor,
      'line-width': 3,
      'line-dasharray': lineDashArray,
    },
  };
  return (
    <Source type={type} data={item} id={id}>
      <Layer source={id} type={layerType} paint={paint[layerType]} />
    </Source>
  );
}

export default PolygonLayer;
