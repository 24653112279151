// I don't know if this is necessary, its more of an experiment.
// If we need an easy way to pass a jwt token as a prop to any component.
// History can judge me.

import React from 'react';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../redux/authSelector';

const JWTContainer = ({ children }) => {
  const jwt = useSelector(getAccessToken);

  const childWithJWT = React.Children.map(children, child =>
    React.cloneElement(child, { jwt })
  );

  return <>{childWithJWT}</>;
};

export default JWTContainer;
