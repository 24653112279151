import { createSelector } from 'redux-views';

export const getDocumentId = state => state.document.documentId;

export const getKnownPositions = state =>
  state.firestore.data['known-vessel-positions'];
export const getPredictedPositions = state =>
  state.firestore.data['predicted-vessel-positions'];
export const getKnownPositionTracks = state =>
  state.firestore.data['vessel-tracks'];

export const searchRadius = state => state.ais.searchRadius;

export const AISKnownPositions = createSelector(
  [getDocumentId, getKnownPositions],
  (documentId, positionCollection) => {
    const document = positionCollection ? positionCollection[documentId] : null;
    return document ? document.url : null;
  }
);

export const AISPredictedPositions = createSelector(
  [getDocumentId, getPredictedPositions],
  (documentId, positionCollection) => {
    const document = positionCollection ? positionCollection[documentId] : null;
    return document ? document.url : null;
  }
);

export const AISTracks = createSelector(
  [getDocumentId, getKnownPositionTracks],
  (documentId, positionCollection) => {
    const document = positionCollection ? positionCollection[documentId] : null;
    return document ? document.url : null;
  }
);
