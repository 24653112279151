// Firebase
export const apiKey = process.env.REACT_APP_API_KEY;
export const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
export const databaseURL = process.env.REACT_APP_DATABASE_URL;
export const projectId = process.env.REACT_APP_PROJECT_ID;
export const storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
export const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
export const appId = process.env.REACT_APP_APP_ID;
export const firebaseConfig = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
};

// Mapbox

export const mapboxKey = process.env.REACT_APP_MAPBOX_KEY;
export const mapStyle = 'mapbox://styles/mapbox/satellite-v9';

export const products = [
  { id: 'ships', label: 'Dark Target Detection' },
  { id: 'spillsight', label: 'Oil Slick Detection' },
];

// default route variables
export const defaultProduct = 'ships';
export const defaultAOI = 'andoya';

export const popupType = {
  IDS: 'IDS',
  AIS: 'AIS',
};

export const aoi = [
  {
    id: 'andoya',
    label: 'Andoya',
    latitude: 69.3996,
    longitude: 15.39,
    zoom: 5.2,
  },
];

export const defaultViewport = {
  ...aoi[0],
  width: '100%',
  height: 'calc(100vh)',
};

export const defaultRadius = 2000; // meters

export const mapVariables = {
  fillOpacity: 1,
  fillColor: '#ffffff',
};

// label - Class field in provdied data
// display - Label to display to the user - used for remapping the constantly changing class names
// color - map pin or fill color
// useClass - used in dropdown in arctic-x edit map popup

export const targetClasses = [
  { label: '', display: '', color: 'white', useClass: false },
  {
    label: 'SHIP',
    display: 'Non Reporting Vessel',
    color: '#e64040',
    useClass: false,
  },
  {
    label: 'Ship_SAR',
    display: 'Non Reporting Vessel (SAR)',
    color: '#e64040',
    useClass: true,
  },
  {
    label: 'Ship_Optical',
    display: 'Non Reporting Vessel (OPT)',
    color: '#e69340',
    useClass: true,
  },
  {
    label: 'Ship_AIS',
    display: 'Reporting Vessel',
    color: '#35c443',
    useClass: true,
  },
  {
    label: 'AIS',
    display: 'Reporting Vessel',
    color: '#35c443',
    useClass: false,
  },
  { label: 'Unknown', display: 'Unknown', color: '#e8e545', useClass: false },
  { label: 'Iceberg', display: 'Iceberg', color: '#b8edff', useClass: false },

  // TODO: I humbly apologise for this: there is a coding error in the data that needs fixing
  {
    label: 'Oil Slick',
    display: 'Oil Slick',
    color: '#bd51ad',
    useClass: false,
  },
  {
    label: 'oil_slick',
    display: 'Oil Slick',
    color: '#bd51ad',
    useClass: false,
  },

  {
    label: 'Provisional',
    display: 'Quick Look',
    color: '#40B5AD',
    useClass: true,
  },
  {
    label: 'IIF',
    display: 'Ice Island Fragment',
    color: '#9999ff',
    useClass: false,
  },
  {
    label: 'ais-location',
    display: 'AIS Location',
    color: '#0088ff',
    useClass: false,
  },
];
