import React from 'react';
import { Provider } from 'react-redux';
import defaultStore from './redux/store';

// Firebase
import FirebaseProvider from './redux/firebaseProvider';

// CCORE
import AuthContainer from './containers/AuthContainer';

import 'mapbox-gl/dist/mapbox-gl.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import MaritimeSurveillanceService from './Pages/MaritimeSurveillance';

// Chakra
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import theme from './theme';

import { defaultAOI, defaultProduct } from './config';

const customTheme = extendTheme(theme);

function App() {
  return (
    <Provider store={defaultStore}>
      <FirebaseProvider store={defaultStore}>
        <ChakraProvider theme={customTheme}>
          <BrowserRouter>
            <AuthContainer>
              <Routes>
                <Route
                  path="/:productId/:aoiId"
                  element={<MaritimeSurveillanceService />}
                />
                <Route
                  path="*"
                  element={
                    <Navigate to={`/${defaultProduct}/${defaultAOI}`} replace />
                  }
                />
              </Routes>
            </AuthContainer>
          </BrowserRouter>
        </ChakraProvider>
      </FirebaseProvider>
    </Provider>
  );
}

export default App;
