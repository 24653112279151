import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import {
  AISKnownPositions,
  AISPredictedPositions,
  AISTracks,
  searchRadius,
} from '../redux/AISSelector';
import GeoJsonLayer from '../components/GeoJsonLayer';
import mapDuck from '../redux/mapDuck';
import debugFactory from 'debug';
import GeoJSONMarkers from './GeoJSONMarkers';
import {
  getAISLayerStatus,
  getPredictedLayerStatus,
} from '../redux/mapSelector';
import { popupType } from '../config';
import { useEffect, useState } from 'react';
import documentDuck from '../redux/documentDuck';

const debug = debugFactory('src/containers/AISLayerContainer.js');

const useUrlToGeoJSON = url => {
  const [geoJSON, setGeoJSON] = useState();

  useEffect(() => {
    fetch(url)
      .then(response => response.json())
      .then(json => {
        setGeoJSON(json);
      });
  }, [url]);

  return geoJSON;
};

const AISLayersContainer = () => {
  const dispatch = useDispatch();

  useFirestoreConnect(() => [
    { collection: 'known-vessel-positions' },
    { collection: 'predicted-vessel-positions' },
    { collection: 'vessel-tracks' },
  ]);

  const AISKnown = useSelector(AISKnownPositions);
  useEffect(() => debug('AISKnown', AISKnown), [AISKnown]);

  const AISPredicted = useSelector(AISPredictedPositions);
  useEffect(() => debug('AISPredicted', AISPredicted), [AISPredicted]);

  const AISTrack = useSelector(AISTracks);
  useEffect(() => debug('AISTrack', AISTrack), [AISTrack]);

  const AISLayerDisplay = useSelector(getAISLayerStatus);
  const predictedLayerDisplay = useSelector(getPredictedLayerStatus);
  const radius = useSelector(searchRadius);

  const handleClick = feature => {
    dispatch(
      mapDuck.actions.setPopup({
        ...feature,
        popupType: popupType.AIS,
      })
    );
  };

  const known_positions = {
    layerId: 'ais-known-positions',
    type: 'circle',
    data: AISKnown,
    layout: {},
    paint: {
      'circle-radius': 2,
      'circle-color': '#ffffff',
    },
  };

  const AISPredictedGeoJSON = useUrlToGeoJSON(AISPredicted);

  useEffect(() => {
    if (AISPredictedGeoJSON !== undefined) {
      const featureWithDistanceFromDetection = AISPredictedGeoJSON.features.find(
        feature => feature.properties.distance_from_detection !== undefined
      );
      dispatch(
        documentDuck.actions.setHasDistanceFromDetectionData(
          featureWithDistanceFromDetection !== undefined
        )
      );
    }
  }, [AISPredictedGeoJSON, dispatch]);

  const predicted_positions = {
    geoJSON: AISPredictedGeoJSON,
    layerId: 'ais-predicted-positions',
    type: 'symbol',
    layout: { 'icon-image': 'water' },
    paint: {
      'icon-color': '#0088ff',
    },
    filter: feature => {
      const distanceFromDetection =
        feature?.properties?.distance_from_detection ?? null;

      if (distanceFromDetection === null) {
        return false;
      }

      return distanceFromDetection < radius;
    },
  };

  const vessel_tracks = {
    data: AISTrack,
    layerId: 'ais-known-tracks',
    beforeId: 'ais-known-positions',
    type: 'line',
    layout: {},
    paint: {
      'line-color': '#ffffff',
    },
  };

  return (
    <>
      {AISPredicted && predictedLayerDisplay && (
        <GeoJSONMarkers {...predicted_positions} onClick={handleClick} />
      )}
      {AISKnown && AISLayerDisplay && <GeoJsonLayer {...known_positions} />}
      {AISTrack && AISLayerDisplay && <GeoJsonLayer {...vessel_tracks} />}
    </>
  );
};

export default AISLayersContainer;
