import {
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

/**
 * Mobile component for selecting menu items
 */
const MenuDrawer = ({
  isOpen,
  menuItems = [],
  onClose,
  menuTitle = 'Menu',
}) => {
  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="sm">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">{menuTitle}</DrawerHeader>
          <DrawerBody>
            {menuItems.length > 0 &&
              menuItems.map(item => {
                // TODO This needs to be a link that supports callbacks and router functions.
                return <Text key={item.title}>{item.title}</Text>;
              })}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default MenuDrawer;
