import {
  Table,
  Tbody,
  Tr,
  Td,
  Flex,
  Box,
  Spacer,
  Button,
  VStack,
  ButtonGroup,
  Link,
} from '@chakra-ui/react';
import { marinetrafficUrl } from '../utils/mmsi';

const defaultRender = value => value;

const columns = {
  MMSI: {
    title: 'MMSI',
    render: mmsi => <Link href={marinetrafficUrl(mmsi)}>{mmsi}</Link>,
  },
  RateKnots: { title: 'Rate (in Knots)', render: defaultRender },
  PredConf: { title: 'Pred Conf', render: defaultRender },
  distance_from_detection: {
    title: 'Distance from Detection',
    render: defaultRender,
  },
  long: { title: 'Long', render: defaultRender },
  lat: { title: 'Lat', render: defaultRender },
};

const AISPopup = ({ data, closePopUp = console.log }) => {
  const [long, lat] = data.geometry.coordinates;

  const properties = { ...data.properties, long, lat };

  return (
    <>
      <Flex flexDirection="row">
        <VStack align="stretch">
          <Table variant="striped" colorScheme="blue" size="sm">
            <Tbody>
              {Object.entries(properties)
                .filter(([k]) => columns[k] !== undefined)
                .map(([k, v]) => (
                  <Tr>
                    <Td>{columns[k].title}</Td>
                    <Td>{columns[k].render(v)}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
          <Spacer />
          <Flex>
            <Box></Box>
            <Spacer />
            <Box>
              <ButtonGroup spacing="2">
                {properties.MMSI && (
                  <Link
                    href={marinetrafficUrl(properties.MMSI)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button size="xs" colorScheme="teal" variant="solid">
                      View in Marine Traffic
                    </Button>
                  </Link>
                )}

                <Button
                  size="xs"
                  colorScheme="blue"
                  variant="solid"
                  onClick={() => closePopUp()}
                >
                  Close
                </Button>
              </ButtonGroup>
            </Box>
          </Flex>
        </VStack>
      </Flex>
    </>
  );
};

export default AISPopup;
