import { useSelector } from 'react-redux';
import SignInContainer from './SignInContainer';
import Spinner from '../components/Spinner';

import { getAccessToken } from '../redux/authSelector';

const AuthContainer = ({ children }) => {
  const auth = useSelector(state => state.firebase.auth);

  // TODO: Demonstrating access token selector below.
  // Will remove once rendering image with bearer token implemented.
  const accessToken = useSelector(getAccessToken);
  window.accessToken = accessToken;

  return auth && !auth.isLoaded ? (
    <Spinner />
  ) : auth && auth.isEmpty ? (
    <SignInContainer />
  ) : (
    children
  );
};

export default AuthContainer;
