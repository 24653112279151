import { Source, Layer } from 'react-map-gl';

const GeoJsonLayer = ({
  data,
  layerId,
  type,
  layout,
  paint,
  filter = ['!=', 'id', 'example'],
}) => {
  return (
    <Source type="geojson" data={data}>
      <Layer
        filter={filter}
        id={layerId}
        type={type}
        layout={layout}
        paint={paint}
      />
    </Source>
  );
};

export default GeoJsonLayer;
