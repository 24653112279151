import PreviousButton from '../components/PreviousButton';
import {
  getDocumentIndex,
  getPreviousDocumentId,
} from '../redux/documentSelector';
import documentDuck from '../redux/documentDuck';
import { useSelector, useDispatch } from 'react-redux';

const NextButtonContainer = () => {
  const dispatch = useDispatch();

  const current = useSelector(getDocumentIndex);
  const previousDocument = useSelector(getPreviousDocumentId);

  const handleClick = () => {
    if (current > 0) {
      dispatch(documentDuck.actions.setDocumentId(previousDocument));
    }
  };

  return (
    <PreviousButton current={current} movePrevious={handleClick} color="gray" />
  );
};

export default NextButtonContainer;
