import { IconButton } from '@chakra-ui/react';

import { ChevronRightIcon } from '@chakra-ui/icons';

const NextButton = (
  { current = 0, max = 0, moveNext = () => {} },
  color = 'white'
) => {
  const isLast = current + 1 === max;
  const handleClick = () => {
    if (!isLast) {
      moveNext();
    }
  };

  return (
    <IconButton
      isRound={true}
      size="sm"
      isDisabled={isLast}
      color={color}
      variant="outline"
      aria-label="Next"
      icon={<ChevronRightIcon boxSize={5} color={color} />}
      onClick={handleClick}
      onKeyDown={event => (event.key === 'ArrowRight' ? handleClick() : null)}
      tabIndex={0}
    />
  );
};

export default NextButton;
