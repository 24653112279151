import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';
import '@firebase/firestore';

import { firebaseConfig as config } from '../config';

const firebaseConfig = {
  ...config,
  debug: false,
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().enablePersistence({ synchronizeTabs: true });
export default firebase;
