import { Box, Flex } from '@chakra-ui/react';

// C-CORE
import NextButtonContainer from './NextButtonContainer';
import PreviousButtonContainer from './PreviousButtonContainer';
import TimesliderContainer from './TimesliderContainer';

const ControlsContainer = ({ height = 'auto', width = 'calc(100vw)' }) => {
  return (
    <Box
      height={height}
      width={width}
      position="fixed"
      left="0px"
      bottom="0px"
      p={3}
      borderRadius="4px"
      layerStyle="translucent.thick"
      zIndex="1000"
    >
      <Flex alignItems="center">
        <PreviousButtonContainer />
        <Box width="calc(100vw - 90px)">
          <TimesliderContainer />
        </Box>
        <NextButtonContainer />
      </Flex>
    </Box>
  );
};

export default ControlsContainer;
