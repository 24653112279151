import {
  MenuList,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  Box,
  Text,
  Spacer,
  Flex,
  Tooltip,
} from '@chakra-ui/react';

import { CheckIcon, DragHandleIcon } from '@chakra-ui/icons';

const AOISelector = ({
  selectedAoi = {},
  handleClick = console.log,
  options = [],
}) => {
  return (
    <Box width="250px" alignContent="" px={5}>
      <Menu closeOnSelect={true}>
        <Flex align="center">
          <Tooltip label="Select AOI">
            <MenuButton
              as={IconButton}
              icon={<DragHandleIcon />}
              aria-label="Options"
              variant="ghost"
              color=""
              rounded="full"
              size="sm"
              mr={5}
            />
          </Tooltip>
          <Text>{selectedAoi.label}</Text>
        </Flex>
        <MenuList width="250px">
          {options &&
            options.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => handleClick(item)}
                width="248px"
              >
                <Text>{item.label}</Text>
                <Spacer />
                {selectedAoi.id === item.id && <CheckIcon color="green.300" />}
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default AOISelector;
