import { createSlice } from '@reduxjs/toolkit';
import { defaultRadius } from '../config';

function setSearchRadius(state, action) {
  const searchRadius = action.payload;
  return { ...state, searchRadius };
}

const aisDuck = createSlice({
  name: 'ais',
  initialState: {
    searchRadius: defaultRadius,
  },
  reducers: {
    setSearchRadius,
  },
});

export default aisDuck;
