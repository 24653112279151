import { createSlice } from '@reduxjs/toolkit';
import { defaultAOI, defaultProduct } from '../config';

function setAOI(state, action) {
  const aoi = action.payload;
  return { ...state, aoi };
}

function setAOIId(state, action) {
  const aoiId = action.payload;
  return { ...state, aoiId };
}

function setProduct(state, action) {
  const product = action.payload;
  return { ...state, product };
}

const aoiDuck = createSlice({
  name: 'aoi',
  initialState: {
    aoiId: defaultAOI,
    aoi: null,
    product: defaultProduct,
  },
  reducers: {
    setAOI,
    setAOIId,
    setProduct,
  },
});

export default aoiDuck;
