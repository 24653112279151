import { createSlice } from '@reduxjs/toolkit';
import debugFactory from 'debug';

const debug = debugFactory('src/redux/documentDuck.js');

function setDocumentId(state, action) {
  const documentId = action.payload;
  debug('documentId', documentId);
  return { ...state, documentId };
}

function setDisplayProvisional(state, action) {
  const displayProvisional = action.payload;
  return { ...state, displayProvisional };
}

function setHasDistanceFromDetectionData(state, action) {
  const hasDistanceFromDetectionData = action.payload;
  return { ...state, hasDistanceFromDetectionData };
}

const documentDuck = createSlice({
  name: 'documentId',
  initialState: {
    documentId: 0,
    displayProvisional: false,
    hasDistanceFromDetectionData: false,
  },
  reducers: {
    setDocumentId,
    setDisplayProvisional,
    setHasDistanceFromDetectionData,
  },
});

export default documentDuck;
