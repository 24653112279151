import {
  Avatar,
  Center,
  MenuItem,
  MenuList,
  Menu,
  MenuButton,
} from '@chakra-ui/react';
// import Amplify from 'aws-amplify'

/**
 * Component for interacting with user's identity
 */
const Identity = ({
  height = '75px',
  p = '4',
  name = null,
  src = null,
  handleSignout = console.log,
}) => {
  return (
    <>
      <Center w="75px" h={height} display={{ base: 'none', md: 'inherit' }}>
        <Menu>
          <MenuButton>
            <Avatar />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleSignout}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </Center>
    </>
  );
};

export default Identity;
