import { Select } from '@chakra-ui/react';

function YearSelector({
  selected_value = 2022,
  values = [2022, 2021],
  onChange = console.log,
}) {
  function handleChange(event) {
    onChange(event);
  }

  return (
    <Select placeholder="Select year" onChange={handleChange}>
      {values.map(value => (
        <option value={value}>{value}</option>
      ))}
    </Select>
  );
}

export default YearSelector;
