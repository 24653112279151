import { MenuButton, Menu, MenuList, MenuItem } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';

const DropdownMenu = ({ menuTitle, items = [] }) => {
  return (
    items.length > 0 && (
      <Menu>
        <MenuButton>
          {menuTitle}
          <ChevronDownIcon />
        </MenuButton>
        <MenuList>
          {/* TODO Icon/actions as props */}
          {items.map((item, i) => (
            <MenuItem key={i} icon={<ChevronRightIcon />}>
              {item.title}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    )
  );
};

export default DropdownMenu;
