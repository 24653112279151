import { Box, Text } from '@chakra-ui/layout';
import { useSelector } from 'react-redux';
import { getDate, getTime } from '../redux/documentSelector';

const StatusContainer = () => {
  const date = useSelector(getDate);
  const time = useSelector(getTime);
  return (
    <Box position="fixed" top="72px" right="10px" align="right">
      <Text fontSize="40px" color="white">
        {date}
      </Text>
      <Text fontSize="25px" color="white">
        {time}
      </Text>
    </Box>
  );
};

export default StatusContainer;
