import { createSelector } from 'redux-views';

export const metadata = state => state.firestore.ordered.metadata;

// This is just the selected document (copied from document selector)
export const spillsightId = state => state.document.documentId;

export const selectedAOI = state => state.aoi.aoi;

export const getFeatures = state => state.firestore.data['spillsight_example'];

export const getSelectedSpillMetadata = createSelector(
  [metadata, spillsightId],
  (collection, id) => {
    const item = collection ? collection.filter(col => col.id === id) : null;

    return item ? item[0] : null;
  }
);

export const spillsightData = createSelector(
  [getFeatures, spillsightId],
  (features, document) => {
    const geoJson = features ? features[document] : false;
    if (!geoJson) return false;
    const parsed = geoJson.features.reduce((accum, feature) => {
      accum.push({
        ...feature,
        geometry: {
          ...feature.geometry,
          coordinates: JSON.parse(feature.geometry.coordinates),
        },
      });
      return accum;
    }, []);
    return parsed;
  }
);

export const getDocumentId = state => state.document.documentId;

export const getSpillsightSummary = createSelector(
  [getDocumentId, getFeatures],
  (documentId, featureCollections) => {
    const feature = featureCollections && featureCollections[documentId];

    if (!featureCollections || documentId === 0 || !feature) return;

    const summary = feature?.features.reduce((classes, feature) => {
      const code = feature.properties.Class;
      if (code in classes) {
        classes[code]++;
      } else {
        classes[code] = 1;
      }
      return classes;
    }, []);
    return summary;
  }
);
