import PolygonLayer from '../components/PolygonLayer';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { getStacItem } from '../redux/documentSelector';

function ExtentLayerContainer() {
  // Get selected documentId from redux state.
  useFirestoreConnect(() => [{ collection: 'stac-items' }]);

  const stacItem = useSelector(getStacItem);
  const extentJson = stacItem?.geometry
    ? JSON.parse(stacItem.geometry)
    : stacItem;
  const geoJson = {
    type: 'Feature',
    properties: {},
    geometry: extentJson,
  };

  return (
    <>
      {stacItem && (
        <PolygonLayer item={geoJson} id={'extents'} beforeId="ids-points" />
      )}
    </>
  );
}

export default ExtentLayerContainer;
