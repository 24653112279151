import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase';

import SignIn from '../components/SignIn';

function SignInContainer() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(undefined);
  const firebase = useFirebase();

  function handleLogin(event) {
    if (email === '') {
      setError('Enter your email');
    } else if (password === '') {
      setError('Enter your password');
    } else {
      setError(undefined);
      setFetching(true);
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => setFetching(true))
        .catch(error => {
          console.error({ error });
          setFetching(false);
          setError(error.message);
        });
    }
  }

  return (
    <SignIn
      handleLogin={handleLogin}
      email={email}
      password={password}
      error={error}
      isLoading={fetching}
      setEmail={setEmail}
      setPassword={setPassword}
    />
  );
}

export default SignInContainer;
