import { Badge, Box, Flex, Spacer } from '@chakra-ui/react';

const TargetSummary = ({ label = 'Target', count = '', control, children }) => {
  return (
    <Flex alignItems="center">
      <Box pr={2}>{children}</Box>
      <Box>{label}</Box>
      <Spacer />
      <Box>{control ? control : <Badge>{count}</Badge>}</Box>
    </Flex>
  );
};
export default TargetSummary;
