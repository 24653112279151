import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  List,
  ListItem,
  Text,
  ListIcon,
  Switch
} from '@chakra-ui/react';

import { MdLinearScale } from 'react-icons/md';

import TargetSummary from '../components/TargetSummary';
import {
  getDisplayProvisional,
  getDocumentIndex,
  getFeatureSummary,
  gethasDistanceFromDetectionData,
  getIsProvisional,
  getProvisionalGeoJson,
} from '../redux/documentSelector';
import {
  getSelectedSpillMetadata,
  getSpillsightSummary,
} from '../redux/spillsightSelector';
import { AISPredictedPositions } from '../redux/AISSelector';
import MarkerPin from '../components/MarkerPin';
import { targetClasses } from '../config';

import mapDuck from '../redux/mapDuck';
import {
  getAISLayerStatus,
  getPredictedLayerStatus,
} from '../redux/mapSelector';
import DistanceSlider from '../components/DistanceSlider';
import documentDuck from '../redux/documentDuck';
import { useEffect } from 'react';

const TargetSummaryContainer = () => {
  const dispatch = useDispatch();
  const current = useSelector(getDocumentIndex);
  const summary = useSelector(getFeatureSummary);
  const spillSummary = useSelector(getSpillsightSummary);
  const spillMetadata = useSelector(getSelectedSpillMetadata);
  const AISDisplayStatus = useSelector(getAISLayerStatus);
  const predictedDisplayStatus = useSelector(getPredictedLayerStatus);
  const provisionalGeoJson = useSelector(getProvisionalGeoJson);

  const labels = summary ? Object.keys(summary) : [];
  const spillLabels = spillSummary ? Object.keys(spillSummary) : [];
  const aisPredicted = useSelector(AISPredictedPositions);
  const isProvisional = useSelector(getIsProvisional);
  const displayProvisional = useSelector(getDisplayProvisional);
  const hasDistanceFromDetectionData = useSelector(
    gethasDistanceFromDetectionData
  );

  const toggleDisplayProvisional = () => {
    dispatch(documentDuck.actions.setDisplayProvisional(!displayProvisional));
  };

  useEffect(() => {
    dispatch(documentDuck.actions.setDisplayProvisional(false));
  }, [current, dispatch]);

  const spill_area = () => {
    const slick_area = spillMetadata?.summary?.oil_slick;
    if (!slick_area) return 'Not Observed';
    if (slick_area < 1) return '< 1 km2';
    return Math.round(slick_area) + ' km2';
  };

  const findColor = label => {
    const item = targetClasses.filter(item => item.label === label);
    return item.length === 1 ? item[0].color : 'black';
  };

  const onAISLayerChange = e => {
    dispatch(mapDuck.actions.setAISLayerStatus(!AISDisplayStatus));
  };

  const onPredictedLayerChange = e => {
    dispatch(mapDuck.actions.setPredictedLayerStatus(!predictedDisplayStatus));
  };

  const mutateClassLabel = label => {
    const item = targetClasses.filter(item => item.label === label);
    return item.length === 1 ? item[0].display : label;
  };

  return (
    <Box w="auto" height="auto" p={5}>
      <List spacing={3}>
        <ListItem>
          <Text size="sm" color="blue.400">
            Target Summary:
          </Text>
        </ListItem>
        {labels.length > 0 ? (
          labels.map(label => (
            <ListItem key={label}>
              <TargetSummary
                label={mutateClassLabel(label)}
                count={summary[label]}
                key={label}
              >
                <ListIcon
                  as={() => (
                    <MarkerPin
                      transform={false}
                      fill={findColor(label)}
                      size={15}
                    />
                  )}
                />
              </TargetSummary>
            </ListItem>
          ))
        ) : (
          <TargetSummary label="No Target Data" count="" />
        )}

        {spillLabels.length && (
          <>
            {spillLabels.map(label => (
              <ListItem key={label}>
                <TargetSummary label={label} count={spill_area()} key={label}>
                  <ListIcon
                    as={() => (
                      <MarkerPin
                        transform={false}
                        fill={findColor(label)}
                        size={15}
                      />
                    )}
                  />
                </TargetSummary>
              </ListItem>
            ))}
          </>
        )}

        {aisPredicted && (isProvisional || displayProvisional) && (
          <>
            {hasDistanceFromDetectionData && (
              <ListItem key="ais-location">
                <TargetSummary
                  label="AIS Location"
                  count={aisPredicted?.feaures?.length}
                  control={
                    <Switch
                      size="sm"
                      onChange={e => onPredictedLayerChange(e)}
                      isChecked={predictedDisplayStatus}
                    />
                  }
                >
                  <ListIcon
                    as={() => (
                      <MarkerPin
                        transform={false}
                        fill={findColor('ais-location')}
                        size={15}
                      />
                    )}
                  />
                </TargetSummary>
                {predictedDisplayStatus && <DistanceSlider />}
              </ListItem>
            )}
            <ListItem key="ais-tracks">
              <TargetSummary
                label="AIS Tracks"
                count={aisPredicted?.feaures?.length}
                control={
                  <Switch
                    size="sm"
                    onChange={e => onAISLayerChange(e)}
                    isChecked={AISDisplayStatus}
                  />
                }
              >
                <ListIcon as={() => <MdLinearScale color="gray" />} />
              </TargetSummary>
            </ListItem>
          </>
        )}
        {!isProvisional && provisionalGeoJson !== undefined && (
          <ListItem>
            <TargetSummary
              label="Display Quick Look?"
              control={
                <Switch
                  size="sm"
                  id="display-prov"
                  isChecked={displayProvisional}
                  onChange={toggleDisplayProvisional}
                />}
            />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default TargetSummaryContainer;
