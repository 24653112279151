import ProductSelector from '../components/ProductSelector';
import { useParams, useNavigate } from 'react-router-dom';
import { products } from '../config';
import { useSelector } from 'react-redux';
import { AOIs } from '../redux/aoiSelector';

const ProductSelectorContainer = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const aoiList = useSelector(AOIs);

  const handleSelect = item => {
    const firstAOI = aoiList.filter(aoi => aoi[item.id])[0].id;
    if (item.id === productId) return;
    navigate(`/${item.id}/${firstAOI}`);
  };

  const product = products.find(item => item.id === productId);

  return (
    <ProductSelector
      selectedProduct={product}
      handleClick={handleSelect}
      options={products}
    />
  );
};
export default ProductSelectorContainer;
