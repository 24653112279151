import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import {
  getDisplayProvisional,
  getGeoJson,
  getProvisionalGeoJson,
} from '../redux/documentSelector';
import { useMemo } from 'react';
import { Marker } from 'react-map-gl';
import MarkerPin from '../components/MarkerPin';
import { Text } from '@chakra-ui/react';
import { targetClasses } from '../config';

const MarkerContainer = ({ handleClick = () => {} }) => {
  useFirestoreConnect(() => [{ collection: 'feature-collections' }]);
  useFirestoreConnect(() => [
    { collection: 'feature-collections-provisional' },
  ]);

  const style = feature => {
    const code = targetClasses.filter(
      cls => cls.label === feature.properties.Class
    ); // ManiceCode -> Class
    return code.length ? code[0] : { color: 'white', text: '' };
  };

  const displayProvisional = useSelector(getDisplayProvisional);
  const actualGeoJson = useSelector(getGeoJson);
  const provisionalGeoJson = useSelector(getProvisionalGeoJson);

  const geoJson =
    displayProvisional && provisionalGeoJson
      ? provisionalGeoJson
      : actualGeoJson;

  const markers = useMemo(
    () =>
      geoJson?.features.map((feature, index) => {
        return (
          <Marker
            key={`marker-${index}`}
            longitude={feature.geometry.coordinates[0]}
            latitude={feature.geometry.coordinates[1]}
          >
            <Text color="white" pl={3} fontSize={11}>
              {style(feature).text}
            </Text>
            <MarkerPin
              fill={style(feature).color}
              onClick={handleClick}
              feature={feature}
            />
          </Marker>
        );
      }),
    [geoJson, handleClick]
  );
  return markers ? markers : null;
};

export default MarkerContainer;
