import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

// Firestore
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

// C-CORE
import documentDuck from './documentDuck';
import mapDuck from './mapDuck';
import aoiDuck from './aoiDuck';
import aisDuck from './aisDuck';

const reducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  document: documentDuck.reducer,
  map: mapDuck.reducer,
  aoi: aoiDuck.reducer,
  ais: aisDuck.reducer,
});

const store = configureStore({
  reducer: reducer,
  middleware: [],
  devTools: true,
});

window.store = store;

export default store;
