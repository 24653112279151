import React, { useMemo } from 'react';
import { Marker } from 'react-map-gl';
import MarkerPin from '../components/MarkerPin';

const GeoJSONMarkers = ({
  geoJSON,
  paint,
  layerId,
  onClick,
  filter = () => true,
}) => {
  const features = useMemo(() => geoJSON?.features.filter(filter), [
    geoJSON,
    filter,
  ]);

  if (!geoJSON) return null;

  return features.map((feature, index) => (
    <Marker
      key={`${layerId}-${index}`}
      latitude={feature.geometry.coordinates[1]}
      longitude={feature.geometry.coordinates[0]}
    >
      <MarkerPin
        fill={paint['icon-color']}
        onClick={onClick}
        feature={feature}
      />
    </Marker>
  ));
};

export default GeoJSONMarkers;
