import {
  Flex,
  Box,
  Image,
  FormControl,
  FormLabel,
  Input,
  Button,
  CircularProgress,
} from '@chakra-ui/react';

import ErrorMessage from './ErrorMessage';
import bgImage from '../images/logInBg.png';
import arcticXLogo from '../images/arctic-x-logo.png';

const SignIn = ({
  handleLogin = console.log,
  email,
  setEmail = console.log,
  password,
  setPassword = console.log,
  error,
  isLoading,
}) => {
  return (
    <Flex
      width="Full"
      align="center"
      height="100vh"
      justifyContent="center"
      bgImage={bgImage}
      bgPosition="bottom"
      bgRepeat="no-repeat"
    >
      <Box
        p={8}
        maxWidth="500px"
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
        bgColor="gray.200"
      >
        <Box textAlign="center">
          <Image src={arcticXLogo} />
        </Box>
        <Box my={4} textAlign="left">
          <form>
            {error && <ErrorMessage message={error} />}
            <FormControl isRequired>
              <FormLabel> Email </FormLabel>
              <Input
                type="email"
                placeholder="test@test.com"
                size="lg"
                onChange={event => setEmail(event.currentTarget.value)}
                autoComplete="username"
              />
            </FormControl>
            <FormControl isRequired mt={6}>
              <FormLabel> Password </FormLabel>
              <Input
                type="password"
                placeholder="********"
                autoComplete="current-password"
                size="lg"
                onChange={event => setPassword(event.currentTarget.value)}
              />
            </FormControl>
            <Button
              variantColor="teal"
              variant="outline"
              width="full"
              mt={4}
              onClick={handleLogin}
            >
              {isLoading ? (
                <CircularProgress isIndeterminate size="24px" color="teal" />
              ) : (
                'Sign In'
              )}
            </Button>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

export default SignIn;
