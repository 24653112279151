// const customTheme = {
//     nav: {
//         baseStyle: {
//             backgroundColor: "rgba(255, 255, 255, 0.3)",
//             backdropFilter: "blur(10px) saturate(100%) contrast(45%) brightness(130%)",
//             borderRadius: "0px"
//         }

//     },
// }

const theme = {
  colors: {
    // This is just a test value..
    ccore: {
      100: 'green',
    },
  },
  layerStyles: {
    translucent: {
      thin: {
        backdropFilter:
          'blur(8px) saturate(100%) contrast(45%) brightness(100%)',
        backgroundColor: 'rgba(250, 250, 250, 0.1)',
        borderRadius: '4px',
      },
      medium: {
        backdropFilter:
          'blur(8px) saturate(100%) contrast(45%) brightness(150%)',
        backgroundColor: 'rgba(250, 250, 250, 0.6)',
        borderRadius: '4px',
      },
      thick: {
        backdropFilter:
          'blur(8px) saturate(100%) contrast(45%) brightness(290%)',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderRadius: '4px',
      },
    },
  },
  components: {
    Tabs: {
      variants: {
        line: {
          borderBottom: '5px solid red',
          paddingTop: '200px',
          marginTop: '200px',
        },
      },
    },
    Box: {
      variants: {
        translucent: {},
      },
    },
  },
};

export default theme;
