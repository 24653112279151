import { createSelector } from 'redux-views';

export const selectedAOIId = state => state.aoi.aoiId;

export const getSelectedProduct = state => state.aoi.product;
export const AOIList = state => state.firestore.data['areas-of-interest'];

export const selectedAOI = createSelector(
  [selectedAOIId, AOIList],
  (selectedAOIId, AOIList) => {
    return AOIList && AOIList[selectedAOIId];
  }
);

export const AOIs = createSelector([AOIList], aoiList => {
  return aoiList && Object.values(aoiList);
});

export const AOIGeoJSON = createSelector([selectedAOI], selectedAOI => {
  return (
    selectedAOI?.geometry && {
      ...selectedAOI,
      geometry: JSON.parse(selectedAOI.geometry),
    }
  );
});
