import { createSlice } from '@reduxjs/toolkit';
import geoViewport from '@mapbox/geo-viewport';

import { defaultViewport, aoi } from '../config';

const initialState = {
  loading: false,
  viewport: defaultViewport,
  selectedItem: null,
  aoi: aoi[0],
  AISLayerStatus: false,
  predictedLayerStatus: false,
};

function setAISLayerStatus(state, action) {
  return {
    ...state,
    AISLayerStatus: action.payload,
  };
}

function setPredictedLayerStatus(state, action) {
  return {
    ...state,
    predictedLayerStatus: action.payload,
  };
}

function setAOI(state, action) {
  const { payload } = action;
  return {
    ...state,
    aoi: payload,
  };
}

function setPopup(state, action) {
  const { payload } = action;
  return { ...state, popup: payload };
}

function setLoaded(state, action) {
  const { payload } = action;

  state.loaded = payload;
}

function setMaxBounds(state, action) {
  // NOTE Max bounds in xmin ymin xmax ymax format
  const { payload } = action;

  return { ...state, maxBounds: payload };
}

function setMinZoom(state, action) {
  const { payload } = action;

  return { ...state, minZoom: payload };
}

function changeViewport(state, action) {
  const { payload } = action;
  const { viewport } = state;

  const { latitude, longitude, zoom } = payload;
  const { maxBounds, minZoom } = state;

  if (!maxBounds) {
    return { ...state, viewport: { ...viewport, ...payload } };
  }
  const [xmin, ymin, xmax, ymax] = maxBounds;

  // Prevent latitude or longitude from creeping when user zooms out
  if (zoom < minZoom) {
    return state;
  }

  const nextLongitude =
    xmin && xmax ? Math.max(Math.min(xmax, longitude), xmin) : longitude;
  const nextLatitude =
    ymin && ymax ? Math.max(Math.min(ymax, latitude), ymin) : latitude;
  const nextZoom = minZoom ? Math.max(Math.max(zoom, minZoom), zoom) : zoom;

  return {
    ...state,
    viewport: {
      ...viewport,
      ...payload,
      longitude: nextLongitude,
      latitude: nextLatitude,
      zoom: nextZoom,
    },
  };
}

function fitBounds(state, action) {
  const { bbox } = action.payload;
  const { viewport } = state;

  const width = viewport.width;
  const height = viewport.height;

  const {
    center: [longitude, latitude],
    zoom,
  } = geoViewport.viewport(bbox, [width, height]);

  return {
    ...state,
    viewport: { ...viewport, latitude, longitude, zoom: zoom - 1 },
  };
}

const map = createSlice({
  name: 'map',
  initialState,
  reducers: {
    changeViewport,
    fitBounds,
    setLoaded,
    setMaxBounds,
    setMinZoom,
    setAOI,
    setPopup,
    setAISLayerStatus,
    setPredictedLayerStatus,
  },
});

export default map;
