import { EditIcon } from '@chakra-ui/icons';
import {
  Table,
  Tbody,
  Tr,
  Td,
  // TableCaption,
  Flex,
  Box,
  Spacer,
  Button,
  VStack,
  ButtonGroup,
  FormControl,
  FormLabel,
  Select,
  Link,
} from '@chakra-ui/react';

import { useState } from 'react';
import JWTContainer from '../containers/JWTContainer';
import { marinetrafficUrl } from '../utils/mmsi';
import SecureImage from './SecureImage';

const IDSPopUp = ({
  data,
  startEditing = console.log,
  cancelEditing = console.log,
  editMode = false,
  handleSaveEdits = console.log,
  closePopUp = console.log,
  targetClasses = [],
}) => {
  const [long, lat] = data.geometry.coordinates;
  // const {id: targetId} = data
  const {
    Class: idsClass, // ManiceCode -> Class
    secure_image_url: url,
    Confidence: ActualConfidence,
    MMSI,
    DetConf,
    TargetNum,
    sensor,
  } = data.properties;

  const mmsi = MMSI === 999999999 ? undefined : MMSI;

  // pre-QC data has Confidence as DetConf, therefore if no actual
  // confidence, default to det conf, and if not def conf, show unknown
  const Confidence = ActualConfidence ?? DetConf ?? 'Unknown';

  const [classValue, setclassValue] = useState(idsClass);

  return (
    <>
      <Flex flexDirection="row">
        <Box w="200px">
          <JWTContainer>
            <SecureImage url={url} />
          </JWTContainer>
        </Box>
        <VStack align="stretch">
          {!editMode ? (
            <>
              <Table variant="striped" colorScheme="blue" size="sm">
                <Tbody>
                  {mmsi && (
                    <Tr>
                      <Td>MMSI</Td>
                      <Td>{mmsi}</Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>ID</Td>
                    <Td>{TargetNum}</Td>
                  </Tr>
                  <Tr>
                    <Td>Class</Td>
                    <Td>{idsClass}</Td>
                  </Tr>
                  {/* <Tr>
                  <Td>Speed</Td>
                  <Td>{targetId}</Td>
                </Tr> */}
                  {/* <Tr>
                  <Td>Heading</Td>
                  <Td>{targetId}</Td>
                </Tr> */}
                  <Tr>
                    <Td>Lat</Td>
                    <Td>{lat.toFixed(5)}</Td>
                  </Tr>
                  <Tr>
                    <Td>Long</Td>
                    <Td>{long.toFixed(5)}</Td>
                  </Tr>
                  <Tr>
                    <Td>Confidence</Td>
                    <Td>{Confidence}</Td>
                  </Tr>
                  {sensor && (
                    <Tr>
                      <Td>Satellite</Td>
                      <Td>{sensor}</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
              <Spacer />
              <Flex>
                <Box></Box>
                <Spacer />
                <Box>
                  <ButtonGroup spacing="2">
                    {mmsi && (
                      <Link
                        href={marinetrafficUrl(mmsi)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button size="xs" colorScheme="teal" variant="solid">
                          View in Marine Traffic
                        </Button>
                      </Link>
                    )}
                    <Button
                      onClick={() => startEditing(true)}
                      leftIcon={<EditIcon />}
                      size="xs"
                      colorScheme="blue"
                      variant="outline"
                    >
                      Edit
                    </Button>
                    <Button
                      size="xs"
                      colorScheme="blue"
                      variant="solid"
                      onClick={() => closePopUp()}
                    >
                      Close
                    </Button>
                  </ButtonGroup>
                </Box>
              </Flex>
            </>
          ) : (
            <>
              <FormControl id="ids-class">
                <FormLabel>Update Target Classification:</FormLabel>
                <Select
                  value={classValue}
                  onChange={event => setclassValue(event.target.value)}
                >
                  {targetClasses.map((target, i) => {
                    return (
                      <option key={i} value={target.label}>
                        {target.label}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <Spacer />
              <Flex>
                <Box></Box>
                <Spacer />
                <Box>
                  <ButtonGroup spacing="2" p={2}>
                    <Button
                      leftIcon={<EditIcon />}
                      onClick={() => cancelEditing()}
                      size="xs"
                      colorScheme="red"
                      variant="outline"
                    >
                      Cancel
                    </Button>
                    <Button
                      size="xs"
                      colorScheme="green"
                      variant="solid"
                      onClick={() => handleSaveEdits(classValue)}
                    >
                      Update
                    </Button>
                  </ButtonGroup>
                </Box>
              </Flex>
            </>
          )}
        </VStack>
      </Flex>
    </>
  );
};

export default IDSPopUp;
