import { useState } from 'react';
import IDSPopUp from '../components/IDSPopUp';
import { targetClasses } from '../config';
import { useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { getDocumentId, getGeoJson } from '../redux/documentSelector';

const IDSPopUpContainer = ({ data = {}, closePopUp = console.log }) => {
  const firestore = useFirestore();
  const documentId = useSelector(getDocumentId);
  const document = useSelector(getGeoJson);
  const featureId = data.id;
  const [editMode, setEditMode] = useState(false);

  const startEditing = () => {
    setEditMode(true);
  };

  const cancelEditing = () => {
    setEditMode(false);
  };

  async function handleSaveEdits(newClass) {
    // Save edits
    // TODO: Fix this is a horrible way of deep cloning a react state variable so we can mutate it.
    let newFeatures = JSON.parse(JSON.stringify(document.features));
    newFeatures[featureId].properties.Class = newClass; // ManiceCode -> Class

    try {
      await firestore
        .collection('feature-collections')
        .doc(documentId)
        .set({ features: newFeatures }, { merge: true });
    } catch (error) {
      console.error(error);
    }
    setEditMode(false);

    // Toast UI confirmation or feedback.
    setEditMode(false);
  }

  return (
    <IDSPopUp
      closePopUp={() => closePopUp()}
      targetClasses={targetClasses}
      startEditing={startEditing}
      cancelEditing={cancelEditing}
      handleSaveEdits={handleSaveEdits}
      editMode={editMode}
      data={data}
    />
  );
};

export default IDSPopUpContainer;
