import { Center, Box, Text } from '@chakra-ui/react';

const MobileHeader = ({ onOpen, title = 'Mobile Title' }) => {
  return (
    <>
      <Center flexGrow="1" p="4" display={{ base: 'block', md: 'none' }}>
        <Text>{title}</Text>
      </Center>
      <Box onClick={onOpen} p="" display={{ base: 'block', md: 'none' }}>
        <Box p={5}>
          <svg
            fill="black"
            width="17px"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Main Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </Box>
      </Box>
    </>
  );
};

export default MobileHeader;
