import { IconButton } from '@chakra-ui/react';

import { ChevronLeftIcon } from '@chakra-ui/icons';

const NextButton = ({
  current = 0,
  max = 0,
  movePrevious,
  color = 'white',
}) => {
  const isFirst = current === 0;
  const handleClick = () => {
    if (!isFirst) {
      movePrevious();
    }
  };

  return (
    <IconButton
      isRound={true}
      size="sm"
      isDisabled={isFirst}
      color={color}
      variant="outline"
      aria-label="Previous"
      icon={<ChevronLeftIcon color={color} boxSize={5} />}
      onClick={handleClick}
      tabIndex={0}
      onKeyDown={event => (event.key === 'ArrowLeft' ? handleClick() : null)}
    />
  );
};

export default NextButton;
