import { createSelector } from 'redux-views';

export const getDocumentId = state => state.document.documentId;

export const getDisplayProvisional = state => state.document.displayProvisional;

export const gethasDistanceFromDetectionData = state =>
  state.document.hasDistanceFromDetectionData;

export const getMetadataOrdered = state => state.firestore.ordered.metadata;

export const getMetadata = state => state.firestore.data.metadata;

export const getFeatureCollections = state =>
  state.firestore.data['feature-collections'];

export const getFeatureCollectionsProvisional = state =>
  state.firestore.data['feature-collections-provisional'];

export const getStacItems = state => state.firestore.data['stac-items'];

export const numberOfItems = createSelector([getMetadataOrdered], metadata => {
  return metadata && metadata.length - 1;
});

export const getIsProvisional = createSelector(
  [getDocumentId, getFeatureCollections],
  (documentId, featureCollections) => {
    const feature = featureCollections && featureCollections[documentId];

    if (!featureCollections || documentId === 0 || !feature) return;

    const isProvisional =
      feature.features?.[0]?.properties?.provisional ?? false;

    return isProvisional;
  }
);

export const getDocumentIndex = createSelector(
  [getDocumentId, getMetadataOrdered],
  (documentId, metadata) => {
    if (metadata) {
      metadata.findIndex(doc => doc.id === documentId);
    }
    return metadata && metadata.findIndex(doc => doc.id === documentId);
  }
);

export const getNextDocumentId = createSelector(
  [getDocumentIndex, getMetadataOrdered],
  (index, metadata) => {
    return metadata && metadata[index + 1]?.id;
  }
);

export const getPreviousDocumentId = createSelector(
  [getDocumentIndex, getMetadataOrdered],
  (index, metadata) => {
    return metadata && metadata[index - 1]?.id;
  }
);

export const getNumberOfDocuments = createSelector(
  [getMetadataOrdered],
  metadata => {
    return metadata?.length;
  }
);

export const getFeatureSummary = createSelector(
  [
    getDocumentId,
    getFeatureCollections,
    getDisplayProvisional,
    getFeatureCollectionsProvisional,
  ],
  (
    documentId,
    featureCollections,
    displayProvisional,
    featureCollectionsProvisional
  ) => {
    const getFeature = () => {
      if (!displayProvisional) {
        return featureCollections && featureCollections[documentId];
      } else {
        return (
          featureCollectionsProvisional &&
          featureCollectionsProvisional[documentId]
        );
      }
    };

    const feature = getFeature();

    if (!featureCollections || documentId === 0 || !feature) return;

    const summary = feature?.features.reduce((classes, feature) => {
      const code = feature.properties.Class; // ManiceCode -> Class
      if (code in classes) {
        classes[code]++;
      } else {
        classes[code] = 1;
      }
      return classes;
    }, []);
    return summary;
  }
);

export const getDate = createSelector(
  [getDocumentId, getMetadata],
  (documentId, metadata) => {
    const seconds = metadata && metadata[documentId]?.datetime;
    return seconds && seconds.toDate().toDateString();
  }
);

export const getTime = createSelector(
  [getDocumentId, getMetadata],
  (documentId, metadata) => {
    const dt = metadata && metadata[documentId]?.datetime.toDate();

    const hours = dt && ('0' + dt.getUTCHours()).slice(-2);
    const minutes = dt && ('0' + dt.getUTCMinutes()).slice(-2);
    return dt && `${hours}:${minutes} UTC`;
  }
);

export const getGeoJson = createSelector(
  [getDocumentId, getFeatureCollections],
  (documentId, featureCollections) => {
    const geoJson = featureCollections && featureCollections[documentId];

    return geoJson;
  }
);

export const getProvisionalGeoJson = createSelector(
  [getDocumentId, getFeatureCollectionsProvisional],
  (documentId, featureCollectionsProvisional) => {
    const feature =
      featureCollectionsProvisional &&
      featureCollectionsProvisional[documentId];

    return feature;
  }
);

export const getStacItem = createSelector(
  [getDocumentId, getStacItems],
  (documentId, stacItems) => {
    const stacItem = stacItems && stacItems[documentId];

    return stacItem;
  }
);
