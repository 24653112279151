import { Center } from '@chakra-ui/react';
import logo from '../images/CCORE _logo_1x.png';

const Logo = ({ height = '75px' }) => {
  return (
    <Center
      minW={{ base: '200px', sm: '0px' }}
      h={height}
      padding="0px 0px"
      display={{ base: 'none', md: 'inherit' }}
    >
      <img src={logo} alt="C-CORE Logo" />
    </Center>
  );
};
export default Logo;
