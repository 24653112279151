import { useSelector } from 'react-redux';

import PolygonLayer from '../components/PolygonLayer';
// import aoiJson from '../images/andoya_aoi.geojson'
import { AOIGeoJSON } from '../redux/aoiSelector';

function ExtentLayerContainer() {
  const aoiJson = useSelector(AOIGeoJSON);

  if (!aoiJson?.geometry) return null;

  return <PolygonLayer item={aoiJson} id="aoi" lineColor="red" />;
}

export default ExtentLayerContainer;
