import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  // SliderMark
} from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import aisDuck from '../redux/aisDuck';
import { searchRadius } from '../redux/AISSelector';

import { defaultRadius } from '../config';

const DistanceSlider = () => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const dispatch = useDispatch();

  const onChange = value => {
    dispatch(aisDuck.actions.setSearchRadius(value));
  };

  const radius = useSelector(searchRadius);

  const formatLabel = value => {
    if (value < 1000) return `${value} M`;
    const km = value / 1000;
    return `${km.toFixed(1)} KM`;
  };

  return (
    <Slider
      min={50}
      max={5000}
      aria-label="radiusSlider"
      defaultValue={defaultRadius}
      value={radius}
      onChange={onChange}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {/* <SliderMark value={50} mt='1' ml='-2.5' fontSize='sm'>
                50m
            </SliderMark>
            <SliderMark value={2000} mt='1' ml='-2.5' fontSize='sm'>
                2km
            </SliderMark>
            <SliderMark value={5000} mt='1' ml='-5' fontSize='sm'>
                5km
            </SliderMark> */}
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <Tooltip
        hasArrow
        bg="teal.500"
        color="white"
        placement="top"
        isOpen={showTooltip}
        label={formatLabel(radius)}
      >
        <SliderThumb />
      </Tooltip>
    </Slider>
  );
};

export default DistanceSlider;
