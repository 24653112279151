import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import TimeBarchart from '../components/TimeBarchart';
import YearSelector from '../components/YearSelector';
import documentDuck from '../redux/documentDuck';
import {
  getMetadataOrdered,
  getDocumentIndex,
  getMetadata,
} from '../redux/documentSelector';

import { useParams } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';

const TimesliderContainer = ({
  defaultMinDate = new Date('2022-01-01') / 1000,
  defaultMaxDate = new Date('2023-01-01') / 1000,
}) => {
  const { aoiId } = useParams();
  useFirestoreConnect(() => [
    {
      collection: 'metadata',
      where: ['aoi', '==', aoiId],
      orderBy: ['datetime', 'asc'],
    },
  ]);

  const docIndex = useSelector(getDocumentIndex);
  const dispatch = useDispatch();
  const [minDate, setMinDate] = useState(defaultMinDate);
  const [maxDate, setMaxDate] = useState(defaultMaxDate);

  function handleChange(document) {
    setCollectionId(document);
  }

  function handleYearChange(event) {
    const year = Number(event.target.value);

    // String values evaluate to `0`
    if (!year) {
      return;
    }

    const nextMinDate = new Date(`${year}-01-01`) / 1000;
    const nextMaxDate = new Date(`${year + 1}-01-01`) / 1000;

    setMinDate(nextMinDate);
    setMaxDate(nextMaxDate);
  }

  const [marks, setMarks] = useState();

  const allMetadata = useSelector(getMetadata);

  const validYears = useMemo(
    () => {
      const years = Array.from(
	  new Set(
	    Object.values(allMetadata ?? {}).map(md =>
	      new Date(md.datetime.seconds * 1000).getFullYear()
	    )
	  )
      )

      // Update minDate and maxDate according to valid years
      const year = years[years.length - 1]
      const nextMinDate = new Date(`${year}-01-01`) / 1000;
      const nextMaxDate = new Date(`${year + 1}-01-01`) / 1000;

      setMinDate(nextMinDate);
      setMaxDate(nextMaxDate);
      
      return (
	years
      )},
    [allMetadata, setMinDate, setMaxDate]
  );

  const metadata = useSelector(getMetadataOrdered);

  useEffect(() => {
    if (metadata?.length) {
      const value = metadata.length - 1;
      const initial_document = metadata[value];

      dispatch(documentDuck.actions.setDocumentId(initial_document.id));

      const chart_data = metadata
        .filter(item => {
          return (
            item.datetime.seconds > minDate && item.datetime.seconds < maxDate
          );
        })
        .map(item => ({
          document: item.id,
          ...item.summary,
          date_raw: item.datetime.toDate(),
          date: formatDate(item.datetime),
        }));

      setMarks(chart_data);
    }
  }, [dispatch, metadata, aoiId, minDate, maxDate]);

  const setCollectionId = val => {
    dispatch(documentDuck.actions.setDocumentId(val));
  };

  function formatDate(value) {
    const date = value.toDate();
    const hours = ('0' + date.getUTCHours()).slice(-2);
    const minutes = ('0' + date.getUTCMinutes()).slice(-2);
    return `${hours}:${minutes} ${date.getUTCDate()}/${
      date.getUTCMonth() + 1
    }/${date.getUTCFullYear()}`;
  }

  return (
    <>
      {marks && (
        <Flex>
          <Flex minWidth="13rem" alignItems="center">
            <YearSelector values={validYears} onChange={handleYearChange} />
          </Flex>
          <TimeBarchart
            data={marks}
            length={marks.length}
            handleClick={handleChange}
            height={100}
            activeIndex={docIndex}
          />
        </Flex>
      )}
    </>
  );
};

export default TimesliderContainer;
