import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactMapGL, { Popup, ScaleControl } from 'react-map-gl';
import { Box } from '@chakra-ui/react';

// CCORE
import MarkerContainer from './MarkerContainer';
import ExtentLayerContainer from './ExtentLayerContainer';
import SpillsightLayerContainer from './SpillsightLayerContainer';
import AOILayerContainer from './AOILayerContainer';
import IDSPopUpContainer from './IDSPopUpContainer';
import documentDuck from '../redux/documentDuck';
import {
  getDocumentIndex,
  getNumberOfDocuments,
  getNextDocumentId,
  getPreviousDocumentId,
  getIsProvisional,
  getDisplayProvisional,
} from '../redux/documentSelector';

//Redux
import { popupContent } from '../redux/mapSelector';
import mapDuck from '../redux/mapDuck';
import { getViewport } from '../redux/mapSelector';

import { mapboxKey, mapStyle, popupType } from '../config';
import AISLayersContainer from './AISLayersContainer';
import AISPopup from '../components/AISPopUp';

const MapContainer = () => {
  const dispatch = useDispatch();
  const viewport = useSelector(getViewport);
  const popupInfo = useSelector(popupContent);

  const setPopupInfo = useCallback(() => {
    dispatch(mapDuck.actions.setPopup(null));
  }, [dispatch]);

  // Handle keyboard input for graph
  const current = useSelector(getDocumentIndex);
  const max = useSelector(getNumberOfDocuments);
  const previousDocument = useSelector(getPreviousDocumentId);
  const nextDocument = useSelector(getNextDocumentId);

  const isProvisional = useSelector(getIsProvisional);
  const displayProvisional = useSelector(getDisplayProvisional);

  useEffect(() => setPopupInfo(), [current, setPopupInfo]);

  const handleKeyboardInput = e => {
    const isFirst = current === 0;
    const isLast = current + 1 === max;
    if (e.key === 'ArrowLeft' && !isFirst) {
      dispatch(documentDuck.actions.setDocumentId(previousDocument));
    } else if (e.key === 'ArrowRight' && !isLast) {
      dispatch(documentDuck.actions.setDocumentId(nextDocument));
    }
  };

  const handleClick = feature => {
    dispatch(
      mapDuck.actions.setPopup({
        ...feature,
        popupType: popupType.IDS,
      })
    );
  };

  const handleViewportChange = useCallback(
    props => {
      dispatch(mapDuck.actions.changeViewport(props));
    },
    [dispatch]
  );

  return (
    <Box zIndex={1} onKeyDown={handleKeyboardInput}>
      <ReactMapGL
        mapboxApiAccessToken={mapboxKey}
        mapStyle={mapStyle}
        {...viewport}
        onViewportChange={handleViewportChange}
        clickRadius={2}
        keyboard={false}
      >
        <MarkerContainer handleClick={handleClick} />
        {(isProvisional || displayProvisional) && <AISLayersContainer />}
        <ExtentLayerContainer />
        <AOILayerContainer />
        <SpillsightLayerContainer />
        {popupInfo?.geometry && (
          <Popup
            tipSize={5}
            anchor="top"
            longitude={popupInfo.geometry.coordinates[0]}
            latitude={popupInfo.geometry.coordinates[1]}
            closeOnClick={false}
            onClose={setPopupInfo}
          >
            {popupInfo.popupType === popupType.IDS && (
              <IDSPopUpContainer
                data={popupInfo}
                closePopUp={() => setPopupInfo(null)}
              />
            )}
            {popupInfo.popupType === popupType.AIS && (
              <AISPopup
                data={popupInfo}
                closePopUp={() => setPopupInfo(null)}
              />
            )}
          </Popup>
        )}
        <ScaleControl className="andoya-scale" />
      </ReactMapGL>
    </Box>
  );
};

export default MapContainer;
