import { Box } from '@chakra-ui/react';
import DropdownMenu from '../components/DropdownMenu';

const MenuContainer = ({ height = '75px', padding = '6', menuItems = [] }) => {
  return (
    <Box
      h={height}
      p={padding}
      flexGrow={1}
      display={{ base: 'none', md: 'block' }}
    >
      <DropdownMenu menuItems={menuItems} />
    </Box>
  );
};

export default MenuContainer;
