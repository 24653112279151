// React
import Header from '../components/Header';
import MenuContainer from './MenuContainer';
import IdentityContainer from '../containers/IdentityContainer';
import MobileHeader from '../components/MobileHeader';
import MobileMenu from '../components/MobileMenu';

// Chakra
import { useDisclosure } from '@chakra-ui/react';

const HeaderContainer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  /**
   * TODO: Parent menu items and submenus:
   * @param [{parentTitle: '', menuItems: []}, ...]
   *
   */
  const menuItems = [];
  return (
    <>
      <Header menuItems={menuItems}>
        {/* <Logo /> */}
        <MenuContainer menuItems={menuItems} />
        <IdentityContainer />
        <MobileHeader onOpen={onOpen} title="" />
        <MobileMenu onClose={onClose} isOpen={isOpen} menuItems={menuItems} />
      </Header>
    </>
  );
};

export default HeaderContainer;
