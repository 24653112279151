import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import { spillsightData } from '../redux/spillsightSelector';
import { selectedAOI } from '../redux/aoiSelector';
import PolygonLayer from '../components/PolygonLayer';

function SpillsightLayerContainer() {
  useFirestoreConnect(() => [{ collection: 'spillsight_example' }]);

  const nullJson = [
    { properties: '', geometry: { type: 'Polygon', coordinates: [] } },
  ];
  const geoJson = useSelector(spillsightData);
  const aoi_meta = useSelector(selectedAOI);

  const featureCollection = {
    type: 'FeatureCollection',
    features: geoJson ? geoJson : nullJson,
  };

  if (!aoi_meta?.spillsight) return null;

  return (
    <PolygonLayer
      item={featureCollection}
      id="spillsight"
      fillColor="#bd51ad"
      layerType="fill"
      fillOutLineColor="#bd51ad"
    />
  );
}

export default SpillsightLayerContainer;
