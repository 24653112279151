import NextButton from '../components/NextButton';
import {
  getDocumentIndex,
  getNumberOfDocuments,
  getNextDocumentId,
} from '../redux/documentSelector';
import documentDuck from '../redux/documentDuck';
import { useSelector, useDispatch } from 'react-redux';

const NextButtonContainer = () => {
  const dispatch = useDispatch();

  const current = useSelector(getDocumentIndex);
  const max = useSelector(getNumberOfDocuments);
  const nextDocument = useSelector(getNextDocumentId);

  const handleClick = () => {
    if (current < max) {
      dispatch(documentDuck.actions.setDocumentId(nextDocument));
    }
  };

  return (
    <NextButton
      current={current}
      max={max}
      moveNext={handleClick}
      color="gray"
    />
  );
};

export default NextButtonContainer;
