import React from 'react';
import {
  MenuList,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  Box,
  Text,
  Spacer,
  Flex,
  Tooltip,
} from '@chakra-ui/react';

import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';

const ProductSelector = ({
  selectedProduct = {},
  handleClick = console.log,
  options,
}) => {
  return (
    <Box width="250px" alignContent="" pl={5} pb={2}>
      <Menu>
        <Flex align="center">
          <Tooltip label="Select Product">
            <MenuButton
              rounded="full"
              as={IconButton}
              icon={<ChevronDownIcon />}
              aria-label="Options"
              variant="ghost"
              // colorScheme="blue"
              size="sm"
              mr={5}
            />
          </Tooltip>
          <Text>{selectedProduct.label}</Text>
        </Flex>
        <MenuList width="250px">
          {options &&
            options.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => handleClick(item)}
                width="248px"
              >
                <Text>{item.label}</Text>
                <Spacer />
                {selectedProduct.id === item.id && (
                  <CheckIcon color="green.300" />
                )}
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
    </Box>
  );
};
export default ProductSelector;
