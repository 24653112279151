import React from 'react';
import MapContainer from '../containers/MapContainer';
import HeaderContainer from '../containers/HeaderContainer';
import ControlsContainer from '../containers/ControlsContainer';
import TargetSummaryContainer from '../containers/TargetSummaryContainer';
import StatusContainer from '../containers/StatusContainer';
import AOISelectorContainer from '../containers/AOISelectorContainer';
import ProductSelectorContainer from '../containers/ProductSelectorContainer';
import aoiDuck from '../redux/aoiDuck';
import { useParams } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import Logo from '../components/Logo';

const MaritimeSurveillance = () => {
  const { aoiId } = useParams();

  const dispatch = useDispatch();
  dispatch(aoiDuck.actions.setAOIId(aoiId));

  return (
    <>
      <HeaderContainer />
      <Flex
        flexDirection="column"
        zIndex="100"
        position="fixed"
        width="300px"
        left="17px"
        top="20px"
        layerStyle="translucent.thick"
      >
        <Logo />
        <ProductSelectorContainer />
        <AOISelectorContainer />
        <TargetSummaryContainer />
      </Flex>
      <MapContainer />
      <ControlsContainer />
      <StatusContainer />
    </>
  );
};

export default MaritimeSurveillance;
