import firebase from '@firebase/app';
import Identity from '../components/Identity';

const handleSignout = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      // Re-direct to '/'
    })
    .catch(error => {
      console.error(error);
    });
};

const IdentityContainer = () => {
  return <Identity handleSignout={handleSignout} />;
};

export default IdentityContainer;
