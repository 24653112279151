import AOISelector from '../components/AOISelector';

import aoiDuck from '../redux/aoiDuck';
import mapDuck from '../redux/mapDuck';
import { useDispatch, useSelector } from 'react-redux';
import { AOIList } from '../redux/aoiSelector';
import { useFirestoreConnect } from 'react-redux-firebase';
import fitBounds from '../utils/fitBounds';
import { useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const AOISelectorContainer = () => {
  useFirestoreConnect(() => [
    {
      collection: 'areas-of-interest',
    },
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { aoiId, productId } = useParams();

  const setMapBounds = useCallback(
    aoi => {
      const bounds = fitBounds({ bounds: aoi.bbox, divisor: 3 });
      dispatch(mapDuck.actions.changeViewport(bounds, 3));
    },
    [dispatch]
  );

  const handleClick = item => {
    if (aoiId === item.id) return;
    navigate(`/${productId}/${item.id}`);
    setMapBounds(item);
  };

  const listAOIs = useSelector(AOIList);
  const currentAOI = listAOIs ? listAOIs[aoiId] : null;
  const availableAOIs = listAOIs
    ? Object.values(listAOIs).filter(aoi => aoi[productId] === true)
    : [];

  useEffect(() => {
    dispatch(aoiDuck.actions.setAOIId(aoiId));
    if (currentAOI) {
      setMapBounds(currentAOI);
    }
  }, [dispatch, currentAOI, aoiId, setMapBounds]);

  if (!listAOIs) return null;

  return (
    <AOISelector
      handleClick={handleClick}
      options={availableAOIs}
      selectedAoi={currentAOI}
    />
  );
};

export default AOISelectorContainer;
