import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { targetClasses } from '../config';
import { Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import documentDuck from '../redux/documentDuck';
import {
  getDocumentIndex,
  getNumberOfDocuments,
  getNextDocumentId,
  getPreviousDocumentId,
  getDocumentId,
} from '../redux/documentSelector';

const fullDTTick = tick => {
  try {
    const days = tick.getUTCDate();
    const months = tick.getUTCMonth() + 1;
    return `${days}/${months}`;
  } catch (error) {
    return '';
  }
};

const TimeBarchart = ({
  data,
  handleClick,
  height = 150,
  activeIndex,
  highlightColor = '#ccc',
}) => {
  const dispatch = useDispatch();
  const handleBarSelect = data => {
    handleClick(data);
  };

  const currentDocumentId = useSelector(getDocumentId);
  const current = useSelector(getDocumentIndex);
  const max = useSelector(getNumberOfDocuments);
  const previousDocument = useSelector(getPreviousDocumentId);
  const nextDocument = useSelector(getNextDocumentId);

  const handleKeyboardInput = e => {
    const isFirst = current === 0;
    const isLast = current + 1 === max;
    if (e.key === 'ArrowLeft' && !isFirst) {
      dispatch(documentDuck.actions.setDocumentId(previousDocument));
    } else if (e.key === 'ArrowRight' && !isLast) {
      dispatch(documentDuck.actions.setDocumentId(nextDocument));
    }
  };

  return (
    <ResponsiveContainer height={height} onKeyDown={handleKeyboardInput}>
      <BarChart
        data={data}
        minTickGap={2}
        barGap={4}
        barSize={20}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 15,
        }}
        onClick={data => {
          if (data && data.activePayload && data.activePayload.length > 0) {
            handleBarSelect(
              data.activePayload[4].payload.document,
              data.activeTooltipIndex
            );
          }
        }}
      >
        <XAxis
          dataKey="date_raw"
          angle={-45}
          textAnchor="end"
          tickFormatter={fullDTTick}
          minTickGap={0}
        />

        <Tooltip content={<Text></Text>} />
        <Bar dataKey="date" stackId="a" fill="none">
          {data &&
            data.map(entry => (
              <Cell
                key={`cell-${entry.document}`}
                fill={
                  entry.document === currentDocumentId ? highlightColor : 'none'
                }
                strokeWidth={4}
                stroke={
                  entry.document === currentDocumentId ? highlightColor : 'none'
                }
              />
            ))}
        </Bar>

        {targetClasses.map((tc, i) => {
          return (
            tc.label && (
              <Bar
                key={i}
                dataKey={tc.label}
                stackId="a"
                fill={tc.color}
                onClick={(d, i) => handleBarSelect(d.document, i)}
              />
            )
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TimeBarchart;
